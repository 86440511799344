"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Imports
 */
var myquery_1 = require("./myquery");
/**
 *  Class init
 */
window.mq = new myquery_1.MyQuery();
