"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyQuery = void 0;
var MyQuery = /** @class */ (function () {
    function MyQuery() {
    }
    MyQuery.prototype.toggleClass = function (selector, className) {
        var element = document.getElementsByClassName(selector)[0];
        if (typeof (element) != 'undefined' && element != null) {
            element.classList.toggle(className);
        }
        return this;
    };
    MyQuery.prototype.chainMe = function () {
        alert('Chain me');
        return this;
    };
    return MyQuery;
}());
exports.MyQuery = MyQuery;
